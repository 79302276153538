import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import { navigate } from 'gatsby';
import './contactform.style.scss';

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(2, "C'mon, your name is longer than that")
      .required(' You do have a name, right?.'),
    email: Yup.string()
      .email('Invalid email address')
      .required("If you don't add an email we can't become pen pals"),
    message: Yup.string().required("Please add a message we won't bite"),
  }),

  mapPropsToValues: ({ user }) => ({
    ...user,
  }),
  handleSubmit: (payload, { setSubmitting }) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name: payload.name,
        email: payload.email,
        message: payload.message,
      }),
    });
    setSubmitting(false);
    navigate('/contact/thanks/');
  },
  displayName: 'MyForm',
});

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const InputFeedback = ({ error }) =>
  error ? <div className='input-feedback'>{error}</div> : null;

const Label = ({ error, className, children, ...props }) => {
  return (
    <label className='label' {...props}>
      {children}
    </label>
  );
};

const textMotion = {
  rest: {
    marginRight: 0,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  hover: {
    marginRight: '10px',
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

const arrowMotion = {
  rest: { opacity: 0, ease: 'easeOut', duration: 0.2, type: 'tween' },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

const TextInput = ({
  type,
  name,
  id,
  placeholder,
  label,
  error,
  value,
  onChange,
  className,
  ...props
}) => {
  const classes = classnames(
    'input-group',
    {
      'animate__animated animate__headShake error': !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <InputFeedback error={error} />
      <input
        name={name}
        id={id}
        className='text-input'
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
    </div>
  );
};
const TextArea = ({
  name,
  id,
  placeholder,
  label,
  error,
  value,
  onChange,
  className,
  maxLength,
  ...props
}) => {
  const classes = classnames(
    'input-group',
    {
      'animate__animated animate__headShake error': !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <InputFeedback error={error} />
      <TextareaAutosize
        name={name}
        id={id}
        className='text-input'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
    </div>
  );
};

const MyForm = (props) => {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
  return (
    <section>
      <div className='form__wrapper'>
        <form
          onSubmit={handleSubmit}
          name='contact'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
        >
          <fieldset>
            <legend>Please fill out the contact form for quickest service.</legend>
            <TextInput
              name='name'
              id='name'
              type='text'
              size='30'
              maxLength='245'
              label='What do we call you?'
              placeholder='Type Your Name Here *'
              error={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              name='email'
              id='email'
              type='text'
              size='30'
              maxLength='100'
              label='Where do we send any emails to?'
              placeholder='Email Address: example@domain.com *'
              error={touched.email && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextArea
              name='message'
              id='message'
              label='What can we help you with today?'
              placeholder='Your Message *'
              error={touched.message && errors.message}
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <input type='hidden' name='form-name' value='contact' />
            <input type='hidden' name='bot-field' />
            <div className='button-group'>
              <motion.button
                type='submit'
                disabled={isSubmitting}
                whileHover='hover'
                initial='rest'
                animate='rest'
              >
                <motion.span variants={textMotion}>Submit</motion.span>
                <motion.span variants={arrowMotion}>&gt;</motion.span>
              </motion.button>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
};

const MyEnhancedForm = formikEnhancer(MyForm);

const ContactForm = () => <MyEnhancedForm user={{ name: '', email: '', message: '' }} />;
export default ContactForm;
