import React from 'react';
import Seo from '../components/seo';
import PageTitle from '../components/pagetitle';
import ContactInfo from '../components/contactinfo';
import ContactForm from '../components/contactform';
import './contact.style.scss';
import AnimatedTitle from '../components/animatedtitle';

const ContactFormPage = () => (
  <main id='main' className='contact'>
    <Seo
      title='Ready. Set. Go '
      description='Send us a line, we can help. From updates to full designs we can help you with whatever your situation may be.'
    />
    <PageTitle
      pageTitle='Say Hello'
      pageTagline="Let's Get Started "
      pageHighlight='Together'
      pageClone='Contact Us'
    />
    <section className='form-info'>
      <div>
        <AnimatedTitle animatedTitle='Our form is open 24/7' />
        <ContactInfo />
      </div>
      <ContactForm />
    </section>
  </main>
);

export default ContactFormPage;
