import React from 'react';

const ContactInfo = () => (
  <section class='page-content'>
    <div className='wrapper'>
      <div className='list-group-item'>
        <h4>City</h4>
        <p>Ballston Spa</p>
      </div>

      <div className='list-group-item'>
        <h4>State</h4>
        <p>New York</p>
      </div>

      <div className='list-group-item'>
        <h4>Phone</h4>
        <p>
          <a title='518-290-8338' href='tel:518-290-8338'>
            518-290-8338
          </a>
        </p>
      </div>
      <div className='list-group-item mb-0 pb-0'>
        <h4>Email</h4>
        <p>
          <a title='hello@innovight.com' href='mailto:hello@innovight.com'>
            hello@innovight.com
          </a>
        </p>
      </div>
    </div>
  </section>
);

export default ContactInfo;
